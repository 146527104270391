/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { isEqual, debounce } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { AccessTime, CheckCircle, Cancel } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { formatAffiliateValue } from '../../../Utilities/getAffiliateIDFromName';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import {
  handleChange as updatePage,
  updateBreadCrumbs
} from '../../../PageContainer/actions';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import BarChart from '../../../Charts/BarChart';
import Table from '../../../TableContainer/Table';
import {
  convertToFormattedNumber,
  numberWithCommas,
  getColumnCompare
} from '../../../TableContainer/TableUtilities/columnFormatter';
import {
  checkAffiliate,
  checkAffiliateManager,
  checkPowerUser,
  checkSuperAffiliate
} from '../../../PermissionsWrappers/permissionChecks';
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../Utilities/saveStateHelper';
import {
  renderReportLink,
  renderReportLinkWithHourly
} from '../../../TableContainer/TableUtilities/RenderReportLink';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import { getReportReports } from '../../requests';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  renderDefaultCell,
  renderCurrencyCell,
  renderPercentCell,
  renderProfit
} from '../../../TableContainer/TableUtilities/defaultCells';
import ChartsWrapper from '../../../Charts/ChartsWrapper';
import ActionDrawer from '../../../PageContainer/ActionDrawer';
import AffiliateBreakdown from './AffiliateBreakdown';
import { colors } from '../../../../Utilities/LenoxColors';
import {
  isMobileDevice,
  formatXAxis,
  getDataSorted
} from '../../../common/utilities';
import ClicksConversionsBreakdown from './ClicksConversionsBreakdown';
import { getMargin } from '../../../Utilities/getMargin';
import AutomaticTesting from '../../../Affiliates/Components/AutomaticTesting';
import Checkbox from '../../../FormContainer/FormComponents/Checkbox';
import { withRouter } from '../../../Utilities/withRouter';
import { logError } from '../../../../Utilities/logError';
import { ROUTE_NAMES } from '../../../NavBar/NavigationContainer';

const baseColumns = [
  { name: 'automatic_testing', title: 'Automatic Testing' },
  { name: 'affiliate_name', title: 'Affiliate', width: 450 },
  { name: 'unique_users', title: 'Uniques', compare: 'priority' },
  {
    name: 'finalRevenue',
    title: 'Estimated Revenue',
    compare: 'priority',
    width: 125
  },
  { name: 'cost', title: 'Cost', compare: 'priority', width: 150 },
  { name: 'profit', title: 'Profit', compare: 'priority' },
  { name: 'margin', title: 'Margin', compare: 'priority' },
  { name: 'converted', title: 'Form Conv.', compare: 'priority' },
  { name: 'conversion_percent', title: 'Form Conv %', compare: 'priority' },
  { name: 'sold_percent', title: 'Sold %', compare: 'priority' },
  { name: 'return_percent', title: 'Return %', compare: 'priority' },
  { name: 'epc', title: 'EPC', compare: 'priority' },
  { name: 'cpc', title: 'CPC', compare: 'priority' },
  { name: 'alv', title: 'RPC', compare: 'priority' },
  { name: 'alvExt', title: 'RPC+', compare: 'priority' },
  { name: 'breakdown', title: 'Actions', width: 135 }
];

const totalMapping = {
  affiliate_name: { type: 'header' },
  unique_users: { type: 'number' },
  unique_users_returns: { type: 'number' },
  to_return: { type: 'number' },
  phoneSales: { type: 'number' },
  sold: { type: 'number' },
  agent_sold: { type: 'number' },
  aggregator_sold: { type: 'number' },
  agent_revenue: { type: 'number' },
  revenue: { type: 'number' },
  email_revenue: { type: 'number' },
  phoneRevenue: { type: 'number' },
  phoneRevenueInbound: { type: 'number' },
  phoneRevenueOutbound: { type: 'number' },
  lb_revenue: { type: 'number' },
  ty_revenue: { type: 'number' },
  ty_offer_revenue: { type: 'number' },
  sms_revenue: { type: 'number' },
  warranty_revenue: { type: 'number' },
  totalRevenue: { type: 'currency' },
  finalRevenue: { type: 'currency' },
  cost: { type: 'currency' },
  profit: { type: 'profit' },
  margin: {
    type: 'percent',
    dividend: 'profit',
    divisor: 'finalRevenue',
    profitColor: true,
    min: -100
  },
  converted: { type: 'number' },
  convertedExt: { type: 'number' },
  peak: { type: 'number' },
  non_peak: { type: 'number' },
  conversion_percent: {
    type: 'percent',
    dividend: ['converted'],
    divisor: 'zip_conversion'
  },
  sold_percent: { type: 'percent', dividend: 'sold', divisor: 'converted' },
  unsold: { type: 'number' },
  return_percent: {
    type: 'percent',
    dividend: 'unique_users_returns',
    divisor: 'sold'
  },
  epc: {
    type: 'average_currency',
    dividend: 'totalRevenue',
    divisor: 'unique_users'
  },
  cpc: {
    type: 'average_currency',
    dividend: 'cost',
    divisor: 'unique_users'
  },
  alv: {
    type: 'average_currency',
    dividend: 'totalRevenue',
    divisor: 'converted'
  },
  alvExt: {
    type: 'average_currency',
    dividend: 'totalRevenue',
    divisor: 'convertedExt'
  },
  breakdown: { type: 'breakdown' }
};

const removeAffiliateColumns = (columns) => {
  columns[3].title = 'Revenue';
  columns.splice(2, 1);
  columns.splice(3, 1);
  columns.splice(3, 1);
  columns.splice(7, 1);
  columns.splice(7, 1);
  columns.splice(7, 1);
  columns.splice(7, 1);
  columns.splice(7, 1);
  columns.splice(7, 1);
};

const buildTableParams = (action, props) => ({
  action,
  start_date: moment(props.startDate).format('YYYY-MM-DD'),
  end_date: moment(props.endDate).format('YYYY-MM-DD'),
  date_range: props.dateRange,
  affiliate_id:
    props.affiliateID === 'none' || props.affiliateID === '0'
      ? 'all'
      : props.affiliateID,
  site_id: props.site === '0' ? 'all' : props.site,
  type: props.affiliateType,
  peak: props.peak,
  traffic_source: props.trafficSource,
  hide_uniques: getFromLocalStorage('affiliate_report_hide_rows', false)
    ? getFromLocalStorage('hide_uniques', 0)
    : 'all'
});

const toLowerCase = (value) => String(value).toLowerCase();

const conversionsBreakdownType = {
  unique_users: 1,
  zip_conversion: 2,
  converted: 3
};

class AffiliateReporting extends Component {
  state = {
    columns: baseColumns,
    csvColumns: [...baseColumns],
    rows: [],
    loading: true,
    hide_rows: getFromLocalStorage('affiliate_report_hide_rows', false),
    lastUpdatedTime: moment(),
    loadingError: false,
    hideUniques: getFromLocalStorage('hide_uniques', 0)
  };

  requestTime = React.createRef();

  getRequestTime = () => this.requestTime.current.toString();

  componentDidMount() {
    this.getData();
    this.changeColumns();
    this.props.handleGetDataChange(this.getData);
    this.props.updateFilters('automaticTestingTableSiteID', this.props.site);
  }

  componentWillUnmount() {
    this.props.handleLastUpdatedChange('');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        buildTableParams('getAffiliateReporting', this.props),
        buildTableParams('getAffiliateReporting', prevProps)
      )
    ) {
      this.getData();
      this.changeColumns();
      this.props.handleLastUpdatedChange('');
    }

    if (this.state.hide_rows !== prevState.hide_rows) {
      this.getData();
    }
  }

  changeColumns = () => {
    const newColumns = [...baseColumns];
    const newCSVColumns = [...baseColumns];

    if (
      checkAffiliate() ||
      checkSuperAffiliate() ||
      checkAffiliateManager() ||
      checkPowerUser()
    ) {
      newColumns.splice(0, 1);
      newCSVColumns.splice(0, 1);
    }

    if (checkAffiliate()) {
      removeAffiliateColumns(newCSVColumns);
    }

    newCSVColumns.unshift({
      name: 'affiliate_id',
      title: 'Aff ID',
      width: 375
    });

    if (checkAffiliate()) {
      removeAffiliateColumns(newColumns);
    }

    this.setState({ columns: newColumns, csvColumns: newCSVColumns });
  };

  getData = () => {
    const requestTime = new Date().getTime() / 1000;
    this.requestTime.current = requestTime;
    this.setState({ loading: true, loadingError: false }, () => {
      getTableDataWithMostUpdated(
        buildTableParams('getAffiliateReporting', this.props),
        getReportReports,
        (values) => {
          if (requestTime.toString() === this.getRequestTime()) {
            this.setState({
              ...values,
              rows: this.addMarginToRows(values.rows)
            });
            const { lastUpdated } = values;
            this.props.handleLastUpdatedChange(lastUpdated);
          }
        }
      ).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      });
    });
  };

  setBreadCrumb = (name, id) => {
    const { breadCrumbs } = this.props;
    this.props.updateBreadCrumbs(
      breadCrumbs,
      'Affiliates',
      'affiliate_reporting',
      name,
      id
    );
  };

  setAffiliateFilter = (affiliate, id, plus) => {
    saveToLocalStorage(`${id}affiliateID`, affiliate);
    saveToLocalStorage('affiliate_detailssite', this.props.site);
    localStorage.setItem('affiliateFilter', affiliate);
    this.props.updateFilters('affiliateID', affiliate);
    this.props.updateFilters('site', this.props.site);
    if (plus) {
      this.props.updateFilters('alvPlus', true);
    }
  };

  setCrumbsAndFilters = (row, id, name, plus = false) => {
    this.setAffiliateFilter(
      `${row.affiliate_name} (#${row.affiliate_id})`,
      id,
      plus
    );
    this.setBreadCrumb(name, id);
  };

  redirectReport = (page, route = false) => {
    this.props.updatePage('selectedPage', page);
    this.props.router.navigate(`/${route || ROUTE_NAMES.Reporting}/${page}`);

    if (route) {
      saveToLocalStorage(`${route}selectedPage`, page);
    }
  };

  handleHideShow = () =>
    this.setState({ hide_rows: !this.state.hide_rows }, () =>
      saveToLocalStorage(`affiliate_report_hide_rows`, this.state.hide_rows)
    );

  renderBreakdownButton = (row) => (
    <div>
      <ActionDrawer
        fullWidth
        drawerTitle="Breakdown"
        content={
          <AffiliateBreakdown
            row={row}
            filters={buildTableParams('getAffiliateReporting', this.props)}
          />
        }
        buttonTestId={`${row.affiliate_name}_BreakdownButton`}
      />
    </div>
  );

  renderBreakdownDrawer = (row, name) => (
    <ActionDrawer
      drawerTitle="Breakdown"
      inline
      buttonTestId={`${row.affiliate_name}_${name}_BreakdownButton`}
      content={
        <ClicksConversionsBreakdown
          row={row}
          params={buildTableParams('getBreakdownReport', this.props)}
          type={conversionsBreakdownType[name]}
        />
      }
    />
  );

  addMarginToRows = (rows) =>
    rows.map((row) => {
      row.margin = getMargin(row.profit, row.finalRevenue);
      return row;
    });

  updateRow = (row, name, value) => {
    const { rows } = this.state;
    rows.forEach(({ affiliate_id }, index) => {
      if (row.affiliate_id === affiliate_id) {
        rows[index][name] = value;
      }
    });
    this.setState({ rows });
  };

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'automatic_testing':
        return renderDefaultCell(
          <ActionDrawer
            fullWidth
            drawerTitle="Automatic Testing"
            content={(toggleDrawer) => (
              <AutomaticTesting
                row={row}
                onChange={this.updateRow}
                toggleDrawer={toggleDrawer}
                filters={buildTableParams('getAffiliateReporting', this.props)}
              />
            )}
            customIcon={
              row.automatic_testing ? (
                <CheckCircle htmlColor="green" />
              ) : (
                <Cancel htmlColor="red" />
              )
            }
          />
        );
      case 'affiliate_name':
        if (checkAffiliate()) {
          return renderReportLink(
            `${row[column.name]} (#${row.affiliate_id})`,
            row,
            (value) => value,
            (row) =>
              this.setCrumbsAndFilters(row, 'affiliate_details', 'Affiliates'),
            () => this.redirectReport('affiliate_details')
          );
        }
        if (this.props.site !== '0') {
          return renderReportLinkWithHourly(
            `${row[column.name]} (#${row.affiliate_id})`,
            row,
            (value) => value,
            (row) =>
              this.setCrumbsAndFilters(row, 'affiliate_details', 'Affiliates'),
            () => this.redirectReport('affiliate_details'),
            AccessTime,
            (row) => {
              this.setCrumbsAndFilters(row, 'hour_report', 'Affiliates');

              // Manipulate Event Loop to be sure Affiliate Filter is applied
              setTimeout(() => {
                this.redirectReport('hour_report');
              });
            }
          );
        }
        return renderDefaultCell(`${row[column.name]} (#${row.affiliate_id})`);
      case 'return_percent':
      case 'sold_percent':
      case 'conversion_percent':
      case 'zip_conversion_percent':
        return renderPercentCell(row[column.name]);
      case 'finalRevenue':
      case 'cost':
      case 'totalRevenue':
      case 'epc':
      case 'cpc':
        return renderCurrencyCell(row[column.name]);
      case 'zip_conversion':
      case 'unique_users':
      case 'converted':
        return renderDefaultCell(
          numberWithCommas(row[column.name]),
          this.renderBreakdownDrawer(row, column.name)
        );
      case 'aggregator_sold':
        return renderDefaultCell(numberWithCommas(row[column.name]));
      case 'profit':
        return renderProfit(row[column.name], '$');
      case 'margin':
        return renderProfit(row[column.name], '', '%');
      case 'breakdown':
        return renderDefaultCell(this.renderBreakdownButton(row));
      case 'alv':
      case 'alvExt':
        return renderReportLink(
          `$${convertToFormattedNumber(row[column.name])}`,
          row,
          (value) => value,
          (row) =>
            this.setCrumbsAndFilters(
              row,
              'alv_report',
              'RPC',
              column.name === 'alvExt'
            ),
          () => this.redirectReport('alv_report')
        );
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  debounceInput = debounce(() => this.getData(), 2000);

  updateHideRowUniques = (e) => {
    if (e.target.value >= 0 && e.target.value <= 100000000) {
      this.setState({ hideUniques: e.target.value }, () => {
        saveToLocalStorage('hide_uniques', this.state.hideUniques);
      });
      if (this.state.hide_rows) {
        this.debounceInput();
      }
    }
  };

  renderUniquesCheckbox = () => {
    return (
      <div style={{ marginTop: 50 }}>
        <Checkbox
          value={this.state.hide_rows}
          name="hide_rows"
          label={
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              HIDE ROWS WITH
              <TextField
                variant="standard"
                style={{ width: '6.2rem', top: -6, margin: '0 5px' }}
                inputProps={{
                  min: 0,
                  max: 100000000,
                  style: { textAlign: 'center', paddingBottom: 0 }
                }}
                defaultValue={0}
                value={this.state.hideUniques}
                type="number"
                onChange={this.updateHideRowUniques}
              />
              {this.state.hideUniques > 1 || this.state.hideUniques === '0'
                ? 'UNIQUES'
                : 'UNIQUE'}{' '}
              {this.state.hideUniques > 0 ? 'OR LESS' : ''}
            </span>
          }
          labelInline
          onChange={this.handleHideShow}
        />
      </div>
    );
  };

  renderBarChart = () => {
    const { rows } = this.state;
    if (!rows.length) {
      return null;
    }
    const topTenRev = getDataSorted(rows, 'finalRevenue').slice(0, 10);
    const rowsSorted = getDataSorted(topTenRev, 'profit').map((row) => ({
      ...row,
      profit: parseFloat(row.profit)
    }));

    return (
      <ChartsWrapper testId="AffiliateReportBarChart">
        <BarChart
          data={rowsSorted}
          barNameKey="affiliate_name"
          formatXAxis={formatXAxis}
          customTick
          setDomain
          setDomainBy="profit"
          bars={[
            {
              dataKey: 'profit',
              label: 'Profit',
              color: colors.lenoxSuccess1,
              negativeColor: colors.lenoxNegative
            }
          ]}
          showDollar
          width={900}
        />
      </ChartsWrapper>
    );
  };

  affiliatePredicate = (value, filter, row) =>
    toLowerCase(row.affiliate_name).indexOf(toLowerCase(filter.value)) > -1 ||
    row.affiliate_id.indexOf(filter.value) > -1;

  render() {
    const integratedFilteringColumnExtensions = [
      { columnName: 'affiliate_name', predicate: this.affiliatePredicate }
    ];
    const {
      rows: stateRows,
      columns,
      csvColumns,
      hide_rows,
      loading,
      loadingError
    } = this.state;

    const finalColumns =
      this.props.site === '0'
        ? columns.filter((col) => col.name !== 'automatic_testing')
        : columns;

    const rows = hide_rows
      ? stateRows.filter((row) => row.unique_users > 0)
      : stateRows;

    return (
      <div>
        {!checkAffiliate() && this.renderBarChart()}
        {!isMobileDevice() && (
          <CSVButton
            params={this.props}
            columns={csvColumns}
            filename="Affiliate Report"
            data={this.state.rows}
            marginTop={(checkAffiliate() || isMobileDevice()) && '3rem'}
            showButton={Boolean(this.state.rows.length) && !loading}
          />
        )}
        {this.renderUniquesCheckbox()}
        <Table
          loading={loading}
          rows={rows}
          columns={finalColumns}
          cellComponent={this.cellComponent}
          defaultColumnWidths={getColumnWidths(columns)}
          getColumnCompare={getColumnCompare(columns)}
          tableColumnLocalStorageName="affiliate_reporting"
          defaultSortingOrder={[
            { columnName: 'totalRevenue', direction: 'desc' }
          ]}
          totalsMapping={totalMapping}
          breakdownButton={this.renderBreakdownButton}
          integratedFilteringColumnExtensions={
            integratedFilteringColumnExtensions
          }
          drawerTitle="Affiliates"
          height="auto"
          loadingError={loadingError}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    peak: state.dateRange.peak,
    site: state.filters.site,
    affiliateID: formatAffiliateValue(state.filters.affiliateID),
    affiliateType: state.filters.affiliateType,
    trafficSource: state.filters.trafficSource,
    breadCrumbs: state.pageReducer.breadCrumbs,
    automaticTestingTableSiteID: state.filters.automaticTestingTableSiteID
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updatePage,
        updateBreadCrumbs,
        updateFilters,
        handleGetDataChange,
        handleLastUpdatedChange
      },
      dispatch
    )
)(withRouter(AffiliateReporting));

/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Paper } from '@mui/material';
import Table from '../../../TableContainer/Table';
import { checkAffiliate } from '../../../PermissionsWrappers/permissionChecks';
import { handleChange as updateFilters } from '../../../Filters/actions/actions';
import { formatAffiliateValue } from '../../../Utilities/getAffiliateIDFromName';
import { getColumnWidths } from '../../../TableContainer/TableUtilities/getColumnWidths';
import {
  getColumnCompare,
  numberWithCommas
} from '../../../TableContainer/TableUtilities/columnFormatter';
import { getReportReports } from '../../requests';
import { getTableDataWithMostUpdated } from '../../../TableContainer/TableUtilities/buildTableRequest';
import {
  renderPercentCell,
  renderDefaultCell,
  renderCurrencyCell,
  renderProfit
} from '../../../TableContainer/TableUtilities/defaultCells';
import CSVButton from '../../../TableContainer/TableUtilities/CSVButton';
import {
  handleGetDataChange,
  handleLastUpdatedChange
} from '../../../lenox/actions';
import { getMargin } from '../../../Utilities/getMargin';
import { isMobileDevice } from '../../../common/utilities';
import { logError } from '../../../../Utilities/logError';
import {
  removeIndexOfColumn,
  returnIndexOfColumn
} from '../../../TableContainer/TableUtilities/columnFilterHelpers';

const baseColumns = [
  {
    name: 'subId',
    title: 'S1',
    compare: 'priority',
    width: isMobileDevice() ? 100 : 300
  },
  { name: 'unique_users', title: 'Uniques', compare: 'priority' },
  { name: 'revenue', title: 'Revenue', width: 150, compare: 'priority' },
  { name: 'cost', title: 'Cost', compare: 'priority' },
  { name: 'profit', title: 'Profit', compare: 'priority' },
  { name: 'epc', title: 'EPC', compare: 'priority' },
  { name: 'margin', title: 'Margin', compare: 'priority' },
  { name: 'converted', title: 'Form Converted', compare: 'priority' },
  { name: 'conversion_percent', title: 'Form Conv. %', compare: 'priority' },
  { name: 'sold', title: 'Sold', compare: 'priority' },
  { name: 'sold_percent', title: 'Sold Percent', compare: 'priority' },
  { name: 'alv', title: 'RPC', compare: 'priority' }
];

const totalMapping = {
  subId: { type: 'header' },
  unique_users: { type: 'number' },
  revenue: { type: 'currency' },
  cost: { type: 'currency' },
  profit: { type: 'profit' },
  epc: {
    type: 'average_currency',
    dividend: checkAffiliate() ? 'cost' : 'revenue',
    divisor: 'unique_users'
  },
  margin: {
    type: 'percent',
    dividend: 'profit',
    divisor: 'revenue',
    profitColor: true,
    min: -100
  },
  converted: { type: 'number' },
  conversion_percent: {
    type: 'percent',
    dividend: 'converted',
    divisor: 'unique_users'
  },
  sold: { type: 'number' },
  sold_percent: { type: 'percent', dividend: 'sold', divisor: 'converted' },
  alv: {
    type: 'average_currency',
    dividend: checkAffiliate() ? 'cost' : 'revenue',
    divisor: 'converted'
  }
};

const changeSubIdName = (columns, subID) => {
  const newColumns = [...columns];

  newColumns[0].title = `S${subID}`;

  return newColumns;
};

const buildTableParams = (action, props) => ({
  action,
  start_date: moment(props.startDate).format('YYYY-MM-DD'),
  end_date: moment(props.endDate).format('YYYY-MM-DD'),
  date_range: props.dateRange,
  affiliateId: props.affiliateID === 'none' ? '' : props.affiliateID,
  site: props.site,
  peak: props.peak,
  subId: props.subID,
  traffic_source: props.trafficSource
});

class AffiliateDetails extends Component {
  state = {
    rows: [],
    columns: [...baseColumns],
    csvColumns: [...baseColumns],
    loading: true,
    loadingError: false
  };

  requestTime = React.createRef();

  getRequestTime = () => this.requestTime.current.toString();

  componentDidMount() {
    this.getData();
    this.changeColumns();
    this.props.updateFilters(
      'affiliateID',
      localStorage.getItem('affiliateFilter') || ''
    );
    this.props.handleGetDataChange(this.getData);
  }

  changeColumns = () => {
    let newColumns = changeSubIdName(baseColumns, this.props.subID);
    let newCSVColumns = changeSubIdName(baseColumns, this.props.subID);

    if (checkAffiliate()) {
      newColumns.splice(4, 1);

      newColumns = removeIndexOfColumn(newColumns, 'revenue');
      newColumns = removeIndexOfColumn(newColumns, 'margin');
      newColumns = removeIndexOfColumn(newColumns, 'profit');
      newColumns = removeIndexOfColumn(newColumns, 'sold');
      newColumns = removeIndexOfColumn(newColumns, 'sold_percent');

      newCSVColumns = removeIndexOfColumn(newCSVColumns, 'revenue');
      newCSVColumns = removeIndexOfColumn(newCSVColumns, 'margin');
      newCSVColumns = removeIndexOfColumn(newCSVColumns, 'profit');
      newCSVColumns = removeIndexOfColumn(newCSVColumns, 'sold');
      newCSVColumns = removeIndexOfColumn(newCSVColumns, 'sold_percent');
    } else {
      const costIndex = returnIndexOfColumn(newColumns, 'cost');
      newColumns[costIndex].title = 'Cost';
      newCSVColumns[costIndex].title = 'Cost';
    }

    newCSVColumns.unshift({
      name: 'affiliate_id',
      title: 'Aff ID',
      width: 375
    });

    this.setState({ columns: newColumns, csvColumns: newCSVColumns });
  };

  componentWillUnmount() {
    this.props.handleLastUpdatedChange('');
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        buildTableParams('getS1Report', this.props),
        buildTableParams('getS1Report', prevProps)
      )
    ) {
      this.getData();
      this.changeColumns();
      this.props.handleLastUpdatedChange('');
    }
  }

  getData = () => {
    const requestTime = new Date().getTime() / 1000;
    this.requestTime.current = requestTime;

    this.setState({ loading: true, loadingError: false }, () => {
      getTableDataWithMostUpdated(
        buildTableParams('getS1Report', this.props),
        getReportReports,
        (values) => {
          if (requestTime.toString() === this.getRequestTime()) {
            this.setState({
              ...values,
              rows: values.rows.map((row) => {
                return { ...row, subId: row[`s${this.props.subID}`] };
              })
            });
            const { lastUpdated } = values;
            this.props.handleLastUpdatedChange(lastUpdated);
          }
        }
      ).catch((error) => {
        logError(error);
        this.setState({ rows: [], loading: false, loadingError: true });
      });
    });
  };

  cellComponent = ({ row, column }) => {
    switch (column.name) {
      case 'sold':
      case 'converted':
      case 'unique_users':
        return renderDefaultCell(numberWithCommas(row[column.name]));
      case 'alv':
      case 'cost':
      case 'revenue':
        return renderCurrencyCell(row[column.name]);
      case 'conversion_percent':
      case 'sold_percent':
        return renderPercentCell(row[column.name]);
      case 'margin':
        return renderProfit(getMargin(row.profit, row.revenue), '', '%');
      case 'profit':
        return renderProfit(row[column.name], '$', '');
      case 'epc':
        return renderCurrencyCell(row[column.name]);
      default:
        return renderDefaultCell(row[column.name]);
    }
  };

  render() {
    const { loading, rows, loadingError, columns, csvColumns } = this.state;

    const newTotalMapping = columns.reduce((acc, column) => {
      const { name } = column;

      return { ...acc, [name]: totalMapping[name] };
    }, {});

    return (
      <div>
        <Paper style={{ padding: '.3rem 1rem' }}>
          <div>
            *Sub ID reporting in Otto does not include inbound phone revenue and
            external offer revenue
          </div>
        </Paper>
        <Paper style={{ padding: '.3rem 1rem', marginTop: '.3rem' }}>
          <div>
            *Due to limitations in reporting, unique click ids will not be shown
            in this report. Please contact support@useotto.tech for any unique
            click id reports.
          </div>
        </Paper>
        {!isMobileDevice() && (
          <CSVButton
            params={this.props}
            columns={csvColumns}
            filename="SubID Report"
            data={rows}
            marginTop="2rem"
            showButton={Boolean(rows.length) && !loading}
          />
        )}
        <Table
          loading={loading}
          rows={rows}
          columns={columns}
          cellComponent={this.cellComponent}
          defaultColumnWidths={getColumnWidths(columns)}
          getColumnCompare={getColumnCompare(columns)}
          tableColumnLocalStorageName="revenue_stream"
          defaultSortingOrder={[{ columnName: 'revenue', direction: 'desc' }]}
          totalsMapping={newTotalMapping}
          drawerTitle="Subid Report"
          loadingError={loadingError}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    startDate: state.dateRange.startDate,
    endDate: state.dateRange.endDate,
    dateRange: state.dateRange.dateRange,
    peak: state.dateRange.peak,
    site: state.filters.site,
    subID: state.filters.subID,
    affiliateID: formatAffiliateValue(state.filters.affiliateID),
    trafficSource: state.filters.trafficSource
  }),
  (dispatch) =>
    bindActionCreators(
      {
        handleGetDataChange,
        handleLastUpdatedChange,
        updateFilters
      },
      dispatch
    )
)(AffiliateDetails);
